import { createContext, useState, useEffect } from "react";

export const UrlActionContext = createContext();

export const UrlActionProvider = ({ children }) => {
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const [action, setAction] = useState(params.get("action"));

  return (
    <UrlActionContext.Provider value={{ params, action }}>
      {children}
    </UrlActionContext.Provider>
  );
};
