import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { ModalContext } from "./contexts/ModalContext.js";

import styles from "../styles/ChatMenuHistoryConsent.module.scss";

const ChatMenuHistoryConsent = () => {
  const { t } = useTranslation();
  const { setDisplayModal } = useContext(ModalContext);

  return (
    <div className={styles.container}>
      <span className={styles.title}>{t("History")}</span>
      <button
        className={styles.buttonActivate}
        onClick={() => setDisplayModal("HistoryConsent")}
      >
        {t("Activate History")}
      </button>
    </div>
  );
};

export default ChatMenuHistoryConsent;
