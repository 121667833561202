const translations = {
  sv: {
    translation: {
      // Swedish translations
      Hello: "Hej",
      Attached: "Bifogad",
      Chat: "Chatta",
      History: "Historik",
      Loading: "Laddar",
      Logo: "Logotyp",
      send: "Skicka",
      close: "Stäng",
      Stop: "Stoppa",
      title: "Titel",
      No: "Nej",

      "Open menu": "Öppna meny",
      "Attach files": "Bifoga filer",
      "Attach image": "Bifoga bild",
      "Attached image": "Bifogad bild",
      "Attaching file": "Bifogar fil",
      "Embedded content": "Inbäddat innehåll",
      "Generated image": "Genererad bild",
      "Hello icon": "Hej ikon",
      "Info icon": "Info ikon",
      "Copy answer": "Kopiera hela svaret",
      "Copy paragraph": "Kopiera stycket",
      "Log out": "Logga ut",
      "Profile picture": "Profilbild",
      "Delete file": "Radera fil",
      "Delete conversation": "Radera konversation",
      "Start new conversation": "Starta ny konversation",
      "Remove image": "Ta bort bild",
      "Window width": "Fönsterbredd",
      "Policy icon": "Policyikon",
      "I understand": "Jag förstår",
      "Activate History": "Aktivera historik",
      "Deactivate History": "Inaktivera historik",
      "Activate now": "Aktivera nu",
      "Deactivate now": "Inaktivera nu",
      "No thanks": "Nej tack!",
      "Unnamed chat": "Namnlös chatt",
      "Generating response": "Genererar svar",
      "Login with Microsoft SSO": "Logga in med Microsoft SSO",

      "When you activate the history feature, the system will start storing your conversations.":
        "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer.",
      "We encrypt all stored conversation history.":
        "Vi krypterar all lagrad konversationshistorik.",
      "A conversation is saved for 90 days.":
        "En konversation sparas i 90 dagar.",
      "If you decide you no longer want your conversations to be stored.":
        "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas.",

      "By disabling history, the system will stop storing your conversations.":
        "Genom att inaktivera historiken slutar systemet att lagra dina konversationer.",
      "You can activate the history feature again.":
        "Du kan givetvis aktivera historikfunktionen igen.",
      "Are you sure you want to disable history and delete all your conversation history?":
        "Är du säker på att du vill inaktivera historiken och radera all din konversationshistorik?",

      "When you activate the history function, the system begins to store your conversations in the service, so that you can resume previous conversations or retrieve previously created material.":
        "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer i tjänsten, så att du kan återuppta tidigare konversationer eller hämta tidigare skapat material.",
      "We of course encrypt all stored conversation history, so that only you have access to it.":
        "Vi krypterar självklart all lagrad konversationshistorik, så att endast du har tillgång till den.",
      "A conversation is saved for 90 days after you last wrote in it, and you can delete a specific conversation whenever you wish.":
        "En konversation sparas i 90 dagar efter att du senast skrev i den, och du kan radera en specifik konversation när du själv önskar.",
      "If you decide that you no longer want your conversations to be saved, you can deactivate the history function at any time, and then your entire conversation history will be erased.":
        "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas, kan du när som helst inaktivera historikfunktionen, och då rensas hela din konversationshistorik.",

      "An unexpected error occurred, please try again later.":
        "Ett oväntat fel uppstod. Försök igen senare.",

      "You can deactivate the conversation history by clicking 'Deactivate history' at the bottom of this menu.":
        'Du kan inaktivera konversationshistoriken genom att klicka på "Inaktivera historik" längst ner i denna meny.',
    },
  },
};

export default translations;
