export const handleRenameChat = async (
  chat,
  chatLabelRef,
  uniqueChatId,
  addMessageBanner
) => {
  if (
    chat.label !== chatLabelRef.current &&
    chat.label.length > 0 &&
    chat.label.length <= 35
  ) {
    try {
      const response = await fetch("/api/conversations/update-label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          conversation_id: uniqueChatId,
          label: chat.label,
        }),
      });

      if (response.ok) {
        // After successfully renaming, update the ref to the new value
        chatLabelRef.current = chat.label;
      } else {
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: "An unexpected error occurred. Failed to update the chat label.",
        });
      }
    } catch (error) {
      console.error("Error renaming conversation:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "An unexpected error occurred and the chat name could not be updated. More information can be found in the browser console.",
      });
    }
  }
};
