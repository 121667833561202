// Import necessary modules and styles
import { useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import { useMsal } from "@azure/msal-react";

import { AuthContext } from "./contexts/AuthContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";
import { ApplicationContext } from "./contexts/ApplicationContext.js";

import styles from "../styles/Login.module.scss"; // Importing CSS styles for the Login component.

// Define the functional component 'Login'
export default function Login() {
  const { t } = useTranslation();
  const { instance, accounts, inProgress } = useMsal();
  const { handleLogin } = useContext(AuthContext);
  const { setMessageBannerObj } = useContext(MessageBannerContext);
  const { applicationConfig } = useContext(ApplicationContext);

  const acquireAndVerifyTokens = async () => {
    try {
      const response = await instance.acquireTokenSilent({
        ...{
          scopes:
            applicationConfig.msal.mode === "legacy"
              ? ["User.Read"]
              : applicationConfig.msal.scopes,
        },
        account: accounts[0],
      });

      const idToken = response.idToken;
      const subject = response.idTokenClaims.sub;
      const accessToken = response.accessToken;

      const verified = await handleLogin(idToken, subject, accessToken);

      if (verified.ok) {
        console.log("MSAL Tokens acquired and verified successfully");
      } else {
        const responseMessage = await verified.json();
        setMessageBannerObj({
          position: "topMiddle",
          type: "failure",
          text: responseMessage.message,
        });
      }
    } catch (e) {
      // fallback to interactive method if silent token acquisition fails
      if (e.name === "InteractionRequiredAuthError") {
        const response = await instance.acquireTokenRedirect({
          ...{
            scopes:
              applicationConfig.msal.mode === "legacy"
                ? ["User.Read"]
                : applicationConfig.msal.scopes,
          },
          account: accounts[0],
        });

        const accessToken = response.accessToken;
        const idToken = response.idToken;

        const verified = await handleLogin(accessToken, idToken);

        if (verified.ok) {
          console.log("MSAL Tokens acquired and verified successfully");
        } else {
          const responseMessage = await verified.json();
          setMessageBannerObj({
            position: "topMiddle",
            type: "failure",
            text: responseMessage.message,
          });
        }
      } else {
        throw e;
      }
    }
  };

  useEffect(() => {
    console.log("inProgress: ", inProgress);
    if (inProgress === "none" && accounts.length > 0) {
      // There is at least one authenticated account, meaning the login was successful
      const userAccount = accounts[0];

      // Accounts have a property 'idTokenClaims' which contains user profile information
      const userClaims = userAccount.idTokenClaims;

      if (userClaims) {
        acquireAndVerifyTokens();
      }
    }
  }, [inProgress, accounts]); // Dependency array includes 'inProgress' and 'accounts'

  // Define the 'handleSubmit' function which uses MS SSO to authenticate the user.
  const handleSubmit = () => {
    instance
      .loginRedirect({
        scopes:
          applicationConfig.msal.mode === "legacy"
            ? ["User.Read"]
            : applicationConfig.msal.scopes,
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Render the Login component.
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={"/logo.png"} alt={t("Logo")} />
      </div>
      {/* Submit button */}
      <button onClick={() => handleSubmit()}>
        {applicationConfig.msal.mode === "legacy"
          ? t("Login with Microsoft SSO")
          : applicationConfig.msal.loginButtonText}
      </button>
    </div>
  );
}
