import styles from "../styles/ChatWidthController.module.scss";

import { useTranslation } from "react-i18next";

export default function ChatWidthController({
  chatWindowWidth,
  setChatWindowWidth,
}) {
  const { t } = useTranslation();
  const updateChatWidth = async (newWidth) => {
    if (newWidth < 600 || newWidth > 2100) {
      return;
    }

    setChatWindowWidth(newWidth);

    try {
      const response = await fetch("/api/auth/update-user-settings", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ settings: { chatWindowWidth: newWidth } }),
      });

      if (!response.ok) {
        console.error("Failed to update user settings");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className={styles.component}>
      {chatWindowWidth > 600 && (
        <span onClick={() => updateChatWidth(chatWindowWidth - 150)}>–</span>
      )}
      {t("Window width")}
      {chatWindowWidth < 2100 && (
        <span onClick={() => updateChatWidth(chatWindowWidth + 150)}>+</span>
      )}
    </div>
  );
}
