import { useState, useEffect, useRef } from "react";

import styles from "../styles/HoverTooltip.module.scss";

export default function HoverTooltip({ content, visible, targetRef }) {
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState("top");

  useEffect(() => {
    if (visible && targetRef.current && tooltipRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const spaceAbove = targetRect.top;
      const spaceBelow = window.innerHeight - targetRect.bottom;

      setPosition(spaceAbove >= tooltipRect.height ? "top" : "bottom");
    }
  }, [visible, targetRef]);
  return (
    <div
      ref={tooltipRef}
      className={[styles.component, styles[position]].join(" ")}
      style={{
        visibility: visible ? "visible" : "hidden",
        opacity: visible ? 1 : 0,
      }}
    >
      {content}
    </div>
  );
}
