import { useContext } from "react";

import { useTranslation } from "react-i18next";

import PromptModeInfo from "./PromptModeInfo.js";

import styles from "../styles/ChatWelcomeMessage.module.scss";

import { ApplicationContext } from "./contexts/ApplicationContext.js";

const ChatWelcomeMessage = ({ name, mode, index }) => {
  const { t } = useTranslation();
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find((m) => m.name === mode);

  const icon = modeConfig?.img || "";
  const label = modeConfig?.label || "";
  const welcomeMessage = modeConfig?.promptModeInfo?.welcomeMessage || "";

  return (
    <div key={index} className={styles.container}>
      <div className={styles.hej}>
        <img src={icon} alt={t("Hello icon")} width={40} />
        <span>{t(label)}</span>
      </div>
      <p>
        {t("Hello")} {name}! {t(welcomeMessage)}
      </p>
      <PromptModeInfo mode={mode} />
    </div>
  );
};

export default ChatWelcomeMessage;
