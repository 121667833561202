import { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";

import { ModalContext } from "./contexts/ModalContext.js";
import { ApplicationContext } from "./contexts/ApplicationContext.js";

import styles from "../styles/ChatMenuHistory.module.scss";

import infoCircle from "../img/info-circle.svg";
import trashIcon from "../img/trash.svg";
import spinner from "../img/spinner.svg";

const ChatMenuHistory = ({
  menu,
  uniqueChatId,
  doRecreateConversation,
  toggleMenu,
}) => {
  const { t } = useTranslation();
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listIsScrollable, setListIsScrollable] = useState(false);
  const { setDisplayModal } = useContext(ModalContext);
  const { applicationConfig } = useContext(ApplicationContext);

  const fetchConversations = async () => {
    try {
      const response = await fetch("/api/conversations/get-conversations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const data = await response.json();
        setConversations(data);
      }
    } catch (error) {
      console.error("Error fetching conversation IDs:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteConversation = async (conversation_id) => {
    try {
      const response = await fetch("/api/conversations/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          conversation_id: conversation_id,
        }),
      });

      if (response.ok) {
        fetchConversations();
      }
    } catch (error) {
      console.error("Error deleting conversation:", error);
    }
  };

  useEffect(() => {
    if (menu.isExpanded) {
      fetchConversations();
    }
  }, [menu.isExpanded]);

  useEffect(() => {
    const historyElement = document.querySelector(`.${styles.history}`);

    if (historyElement) {
      const isScrollable =
        historyElement.scrollHeight > historyElement.clientHeight;

      if (isScrollable) {
        setListIsScrollable(true);
      } else {
        setListIsScrollable(false);
      }
    }
  }, [conversations]);

  const getModeImage = (promptMode) => {
    const mode = applicationConfig?.modes?.find(
      (mode) => mode.name === promptMode
    );
    return mode?.img;
  };

  return (
    <div className={styles.container}>
      <div className={styles.topper}>
        <span className={styles.title}>{t("History")}</span>
        <div className={styles.info}>
          <img src={infoCircle} alt={t("Info icon")} width={20} />
          <span className={styles.tooltip}>
            {t(
              "You can deactivate the conversation history by clicking 'Deactivate history' at the bottom of this menu."
            )}
          </span>
        </div>
      </div>
      {isLoading ? ( // Check if the data is still loading
        // Display spinner
        <div className={styles.spinner}>
          <img src={spinner} alt="Loading" width={50} />
        </div>
      ) : (
        <>
          <ul
            className={[
              styles.history,
              listIsScrollable ? styles.scrollable : "",
            ].join(" ")}
          >
            {Array.isArray(conversations) &&
              conversations.map((conversation, index) => (
                <div key={index}>
                  <div className={styles.imgAndLabel}>
                    <img
                      src={getModeImage(conversation.prompt_mode)}
                      alt={`${conversation.prompt_mode} icon`}
                      className={styles.promptIcon}
                      width={16}
                    />
                    <li
                      key={index}
                      onClick={() => {
                        toggleMenu();
                        doRecreateConversation(conversation.conversation_id);
                      }}
                      className={
                        conversation.conversation_id === uniqueChatId &&
                        styles.active
                      }
                    >
                      {conversation.label}
                    </li>
                  </div>
                  <div className={styles.dateAndTrash}>
                    <span>
                      {new Date(conversation.timestamp).toLocaleString(
                        "sv-SE",
                        {
                          day: "numeric",
                          month: "numeric",
                        }
                      )}
                    </span>
                    <img
                      src={trashIcon}
                      onClick={() =>
                        deleteConversation(conversation.conversation_id)
                      }
                      alt={t("Delete conversation")}
                      width={20}
                    />
                  </div>
                </div>
              ))}
          </ul>
          <div className={styles.disableHistory}>
            <span
              onClick={() => setDisplayModal("DeactivateConversationHistory")}
            >
              {t("Deactivate History")}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatMenuHistory;
