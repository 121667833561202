import { useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import HoverTooltip from "./HoverTooltip.js";

import styles from "../styles/ChatTopper.module.scss";

import newConversation from "../img/new-conversation.svg";

export default function ChatTopper({ doHandleNewChat }) {
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div className={styles.component}>
      <div className={styles.newConversation}>
        <img
          ref={targetRef}
          src={newConversation}
          alt={t("Start new conversation")}
          className={styles.newConversationImg}
          width={40}
          onClick={() => doHandleNewChat()}
          onMouseEnter={() => setIsTooltipVisible(true)}
          onMouseLeave={() => setIsTooltipVisible(false)}
        />
        <HoverTooltip
          content={t("Start new conversation")}
          visible={isTooltipVisible}
          targetRef={targetRef}
        />
      </div>
    </div>
  );
}
