import { useEffect, useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import styles from "../styles/Topper.module.scss";

import { AuthContext } from "./contexts/AuthContext.js";
import poweredByAi from "../img/powered-by-ai.png";
import genericUserPhoto from "../img/generic-user.png";
import languageSwitcherIcon from "../img/language-white.svg";
import crossWhiteIcon from "../img/cross-white.svg";

export default function Topper() {
  const { t, i18n } = useTranslation();
  const { user, handleLogout } = useContext(AuthContext);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const languageMenuRef = useRef(null);

  const toggleMenu = (e) => {
    if (e) {
      e.stopPropagation();
    }

    setLanguageMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (user.authorized && user.settings) {
      let userSettings = user.settings;

      if (typeof user.settings === "string") {
        try {
          userSettings = JSON.parse(user.settings);
        } catch (error) {
          console.error("Error parsing user settings:", error);
        }
      }

      if (userSettings.preferredLanguage) {
        if (userSettings.preferredLanguage !== i18n.language) {
          i18n.changeLanguage(userSettings.preferredLanguage);
        }
      }
    }
  }, [user]);

  const refreshPage = () => {
    window.location.reload();
  };

  const changeLanguage = async (lng) => {
    setLanguageMenuOpen(false);
    i18n.changeLanguage(lng);

    try {
      const response = await fetch("/api/auth/update-user-settings", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ settings: { preferredLanguage: lng } }),
      });

      if (!response.ok) {
        console.error("Failed to update user settings");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Adding event listener when menu is expanded and removing it when collapsed
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        languageMenuOpen &&
        languageMenuRef.current &&
        !languageMenuRef.current.contains(event.target)
      ) {
        // Clicked outside the menu, close menu
        toggleMenu();
      }
    };

    // Function to handle escape key press
    const handleEscapePress = (event) => {
      if (languageMenuOpen && event.key === "Escape") {
        toggleMenu();
      }
    };

    if (languageMenuOpen) {
      // Delay adding event listeners until after the current event loop
      setTimeout(() => {
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("keydown", handleEscapePress);
      }, 0);
    }

    // Clean up the event listener on unmount or when the menu is collapsed
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapePress);
    };
  }, [languageMenuOpen]);

  return (
    <div className={styles.component}>
      <div className={styles.leftObjects}>
        <img
          src={"/logo.png"}
          alt={t("Logo")}
          style={{ width: 236, height: "auto", cursor: "pointer" }}
          onClick={refreshPage}
        />
        <img src={poweredByAi} width={127.5} alt="Powered by AI" />
      </div>
      <div className={styles.rightObjects}>
        <div>
          <h1>{user.displayName}</h1>
          <span onClick={handleLogout}>{t("Log out")}</span>
        </div>
        <img
          src={user.photo || genericUserPhoto}
          alt={user.displayName}
          style={{ width: 64, height: 64, opacity: user.photo ? 1 : 0.2 }}
        />
        <div className={styles.languageSwitcher} onClick={() => toggleMenu()}>
          <img
            src={languageMenuOpen ? crossWhiteIcon : languageSwitcherIcon}
            width={20}
            alt="Language switcher"
          />
        </div>
        <div
          className={styles.languageMenu}
          style={{ display: languageMenuOpen ? null : "none" }}
          ref={languageMenuRef}
        >
          <button onClick={() => changeLanguage("en")}>English</button>
          <button onClick={() => changeLanguage("sv")}>Svenska</button>
        </div>
      </div>
    </div>
  );
}
