import { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext.js";

const CustomUnauthenticatedTemplate = ({ children }) => {
  const { user } = useContext(AuthContext);

  return !user.authorized ? <>{children}</> : null;
};

export default CustomUnauthenticatedTemplate;
