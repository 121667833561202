import { useContext, useEffect, useRef, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { MessageBannerContext } from "./contexts/MessageBannerContext.js";

import styles from "../styles/MessageBanner.module.scss";

import closeSquare from "../img/close-square.svg";

const FADE_DURATION = 200;
const DISPLAY_DURATION = 8000;

export default function MessageBanner() {
  const { t } = useTranslation();
  const { messageBanners, removeMessageBanner } =
    useContext(MessageBannerContext);
  const animationRefs = useRef({});
  const remainingTimeRefs = useRef({});
  const startTimeRefs = useRef({});
  const elementRefs = useRef({});

  const handleClose = useCallback(
    (id, event) => {
      event?.stopPropagation();

      // Get the banner element
      const element = elementRefs.current[id];
      if (!element) {
        removeMessageBanner(id);
        return;
      }

      // Clear the auto-dismiss timeout
      if (animationRefs.current[id]) {
        clearTimeout(animationRefs.current[id]);
        delete animationRefs.current[id];
      }

      // Add fadeOut class and wait for animation to complete
      element.classList.add(styles.fadeOut);
      setTimeout(() => {
        // Clean up all refs
        delete remainingTimeRefs.current[id];
        delete startTimeRefs.current[id];
        delete elementRefs.current[id];

        // Remove the banner
        removeMessageBanner(id);
      }, FADE_DURATION);
    },
    [removeMessageBanner]
  );

  const startTimer = useCallback(
    (id, duration) => {
      startTimeRefs.current[id] = Date.now();
      animationRefs.current[id] = setTimeout(() => {
        handleClose(id);
      }, duration);
    },
    [handleClose]
  );

  const handleMouseEnter = useCallback((id) => {
    if (animationRefs.current[id]) {
      clearTimeout(animationRefs.current[id]);

      const elapsedTime = Date.now() - startTimeRefs.current[id];
      remainingTimeRefs.current[id] = Math.max(
        DISPLAY_DURATION - elapsedTime,
        0
      );
    }
  }, []);

  const handleMouseLeave = useCallback(
    (id) => {
      if (remainingTimeRefs.current[id]) {
        startTimer(id, remainingTimeRefs.current[id]);
      }
    },
    [startTimer]
  );

  useEffect(() => {
    // Handle new banners
    messageBanners.forEach((banner) => {
      if (!animationRefs.current[banner.id]) {
        startTimer(banner.id, DISPLAY_DURATION);
      }
    });

    // Cleanup removed banners
    return () => {
      Object.entries(animationRefs.current).forEach(([id, timer]) => {
        if (!messageBanners.find((banner) => banner.id === id)) {
          clearTimeout(timer);
          delete animationRefs.current[id];
          delete remainingTimeRefs.current[id];
          delete startTimeRefs.current[id];
          delete elementRefs.current[id];
        }
      });
    };
  }, [messageBanners, startTimer]);

  return (
    <div className={styles.container}>
      {messageBanners.map((banner) => {
        const typeClass = styles[banner.type];
        const positionClass = styles[banner.position];
        return (
          <div
            key={banner.id}
            className={`${typeClass} ${positionClass}`}
            onMouseEnter={() => handleMouseEnter(banner.id)}
            onMouseLeave={() => handleMouseLeave(banner.id)}
            ref={(el) => (elementRefs.current[banner.id] = el)}
          >
            <div className={styles.progressBar}>
              <div className={styles.progress} />
            </div>
            <div className={styles.close}>
              <img
                src={closeSquare}
                onClick={(e) => handleClose(banner.id, e)}
                width={20}
                alt={t("Close")}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleClose(banner.id, e);
                  }
                }}
              />
            </div>
            {banner.text}
          </div>
        );
      })}
    </div>
  );
}
