import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import styles from "../styles/PromptTextarea.module.scss";

export default function PromptTextarea({
  prompt,
  setPrompt,
  chat,
  doHandleQuery,
}) {
  const { t } = useTranslation();
  const INITIAL_TEXTAREA_HEIGHT = "52";
  const MAX_TEXTAREA_HEIGHT = "190";

  const [textareaHeight, setTextareaHeight] = useState(INITIAL_TEXTAREA_HEIGHT);
  const textareaRef = useRef(null);

  // This effect handles the resizing of the textarea
  useEffect(() => {
    if (textareaRef.current) {
      const { scrollHeight, clientHeight } = textareaRef.current;

      if (scrollHeight > clientHeight + 16) {
        setTextareaHeight(scrollHeight);
      }

      if (!prompt.content) {
        setTextareaHeight(`${INITIAL_TEXTAREA_HEIGHT}px`);
      }
    }
  }, [prompt.content]);

  // This effect handles the scrollbar visibility
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.overflowY =
        textareaRef.current.clientHeight > MAX_TEXTAREA_HEIGHT
          ? "visible"
          : "hidden";
    }
  }, [textareaHeight]);

  // Focus the textarea when the component mounts
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  const handleEnterSubmit = (e) => {
    if (e && e.key === "Enter" && !e.shiftKey) {
      if (prompt.content && !chat.waiting) {
        doHandleQuery(e);
      }
    }
  };

  return (
    <textarea
      className={styles.component}
      type="text"
      name="user_prompt"
      value={prompt.content}
      style={{ height: textareaHeight }}
      onKeyDown={handleEnterSubmit}
      onChange={(e) =>
        setPrompt({
          ...prompt,
          content: e.target.value,
        })
      }
      ref={textareaRef}
      placeholder={t("Chat")}
      required
    />
  );
}
