import { useContext } from "react";
import { AuthContext } from "./contexts/AuthContext.js";

const CustomAuthenticatedTemplate = ({ children }) => {
  const { user } = useContext(AuthContext);

  return user.authorized ? <>{children}</> : null;
};

export default CustomAuthenticatedTemplate;
