import { useContext, useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { AuthContext } from "./contexts/AuthContext.js";
import { ModalContext } from "./contexts/ModalContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";

import styles from "../styles/Modal.module.scss";

import spinner from "../img/spinner.svg";
import DocumentWithPenIcon from "../img/document-with-pen.svg";
import DocumentWithLockIcon from "../img/document-with-lock.svg";

export default function Modal() {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);
  const { displayModal, setDisplayModal } = useContext(ModalContext);
  const { addMessageBanner } = useContext(MessageBannerContext);
  const { applicationConfig } = useContext(ApplicationContext);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkboxWarning, setCheckboxWarning] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");
  const [loading, setLoading] = useState(false);
  const dialogRef = useRef(null);

  const modalConfig = applicationConfig.modals.find(
    (m) => m.name === displayModal
  );

  // Constants for modal names to prevent typos
  const MODAL_NAMES = {
    INITIAL_POLICY: "InitialPolicy",
    TEMPORARY_ACCESS_POLICY: "TemporaryAccessPolicy",
    HISTORY_CONSENT: "HistoryConsent",
    DEACTIVATE_HISTORY: "DeactivateConversationHistory",
  };

  const ACTION_BUTTONS = [
    "AcceptInitialPolicy",
    "AcceptTemporaryAccessPolicy",
    "AcceptHistoryConsent",
    "DeactivateConversationHistory",
  ];

  const MODAL_CONFIGS = {
    [MODAL_NAMES.INITIAL_POLICY]: {
      icon: {
        src: DocumentWithLockIcon,
        alt: t("Policy icon"),
        width: 50,
        height: 50,
      },
      className: styles.initialPolicy,
      buttonContainer: {
        buttons: [
          {
            name: "AcceptInitialPolicy",
            text: t("I understand") + "!",
          },
        ],
      },
    },
    [MODAL_NAMES.TEMPORARY_ACCESS_POLICY]: {
      icon: {
        src: DocumentWithLockIcon,
        alt: t("Policy icon"),
        width: 50,
        height: 50,
      },
      className: styles.initialPolicy,
      buttonContainer: {
        buttons: [
          {
            name: "AcceptTemporaryAccessPolicy",
            text: t("I understand") + "!",
          },
        ],
      },
    },
    [MODAL_NAMES.HISTORY_CONSENT]: {
      icon: {
        src: DocumentWithPenIcon,
        alt: t("Policy icon"),
        width: 50,
        height: 50,
      },
      title: t("Activate History"),
      paragraphs: [
        "When you activate the history function, the system begins to store your conversations in the service, so that you can resume previous conversations or retrieve previously created material.",
        "We of course encrypt all stored conversation history, so that only you have access to it.",
        "A conversation is saved for 90 days after you last wrote in it, and you can delete a specific conversation whenever you wish.",
        "If you decide that you no longer want your conversations to be saved, you can deactivate the history function at any time, and then your entire conversation history will be erased.",
      ],
      className: styles.historyConsent,
      buttonContainer: {
        buttons: [
          {
            name: "ExitModal",
            text: t("No thanks"),
          },
          {
            name: "AcceptHistoryConsent",
            text: t("Activate now") + ".",
          },
        ],
      },
    },
    [MODAL_NAMES.DEACTIVATE_HISTORY]: {
      icon: {
        src: DocumentWithPenIcon,
        alt: t("Policy icon"),
        width: 50,
        height: 50,
      },
      title: t("Deactivate History"),
      paragraphs: [
        "By disabling history, the system will stop storing your conversations.",
        "You can activate the history feature again.",
        "Are you sure you want to disable history and delete all your conversation history?",
      ],
      className: styles.deactivateConversationHistory,
      buttonContainer: {
        buttons: [
          {
            name: "ExitModal",
            text: t("No") + "!",
          },
          {
            name: "DeactivateConversationHistory",
            text: t("Deactivate now") + ".",
          },
        ],
      },
    },
  };

  // Combine static and dynamic config
  const currentModalConfig = {
    ...MODAL_CONFIGS[displayModal],
    ...modalConfig,
  };

  const handleApiRequest = async (endpoint, method = "PUT", onSuccess) => {
    setLoading(true);
    try {
      const response = await fetch(endpoint, {
        method,
        credentials: "include",
      });

      const contentType = response.headers.get("Content-Type");
      const isJson = contentType?.includes("application/json");

      // Read the response body once and store it
      let responseData = null;
      if (isJson) {
        try {
          responseData = await response.json();
        } catch (e) {
          console.error("Failed to parse JSON response:", e);
        }
      }

      if (!response.ok) {
        throw new Error(responseData?.message || "Request failed");
      }

      onSuccess();

      if (responseData?.message) {
        addMessageBanner({
          position: "topMiddle",
          type: "success",
          text: responseData.message,
        });
      }
    } catch (error) {
      console.error("API request failed:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: t("An unexpected error occurred, please try again later."),
      });
    } finally {
      setLoading(false);
      setDisplayModal("");
    }
  };

  const handleAction = async (actionName) => {
    const actions = {
      AcceptInitialPolicy: () => {
        if (currentModalConfig.useCheckbox && !checkboxChecked) {
          setCheckboxWarning(currentModalConfig.checkboxWarning);
          return;
        }

        handleApiRequest("/api/auth/accept-initial-policy", "PUT", () =>
          setUser((prev) => ({ ...prev, initialPolicy: 1 }))
        );
      },

      AcceptTemporaryAccessPolicy: () => {
        if (currentModalConfig.useCheckbox && !checkboxChecked) {
          setCheckboxWarning(currentModalConfig.checkboxWarning);
          return;
        }
        setDisplayModal("");
      },

      AcceptHistoryConsent: () => {
        handleApiRequest("/api/conversations/activate-history", "PUT", () =>
          setUser((prev) => ({ ...prev, conversations: 1 }))
        );
      },

      DeactivateConversationHistory: () => {
        handleApiRequest("/api/conversations/deactivate-history", "PUT", () =>
          setUser((prev) => ({ ...prev, conversations: 0 }))
        );
      },

      ExitModal: () => setDisplayModal(""),
    };

    if (!Object.keys(actions).includes(actionName)) {
      console.warn(`Unknown action: ${actionName}`);
      return;
    }

    await actions[actionName]?.();
  };

  useEffect(() => {
    const currentDialog = dialogRef.current;
    if (currentDialog) {
      currentDialog.showModal();
      return () => currentDialog.close();
    }
  }, [displayModal]);

  useEffect(() => {
    if (currentModalConfig?.loadMarkdownFromFile) {
      fetch(`/${displayModal}.md`)
        .then((response) => {
          if (!response.ok) throw new Error("Failed to fetch markdown");
          return response.text();
        })
        .then(setMarkdownContent)
        .catch((error) => {
          console.error("Error fetching markdown:", error);
          setMarkdownContent("");
        });
    }
  }, [displayModal, currentModalConfig?.loadMarkdownFromFile]);

  if (!displayModal || !currentModalConfig) return null;

  return (
    <dialog
      ref={dialogRef}
      className={`${styles.dialog} ${currentModalConfig.className}`}
      onClose={() => setDisplayModal("")}
    >
      <img
        src={
          currentModalConfig.useLogo ? "/logo.png" : currentModalConfig.icon.src
        }
        alt={currentModalConfig.useLogo ? "Logo" : currentModalConfig.icon.alt}
        width={
          !currentModalConfig.useLogo
            ? currentModalConfig.icon.width
            : undefined
        }
        height={
          !currentModalConfig.useLogo
            ? currentModalConfig.icon.height
            : undefined
        }
        className={currentModalConfig.useLogo ? styles.logo : ""}
      />

      <h1>{t(currentModalConfig.title)}</h1>

      {currentModalConfig.loadMarkdownFromFile ? (
        <Markdown remarkPlugins={[remarkGfm]}>{markdownContent}</Markdown>
      ) : (
        currentModalConfig.paragraphs?.map((paragraph, index) => (
          <p key={index} style={{ textAlign: "center" }}>
            {t(paragraph)}
          </p>
        ))
      )}

      {currentModalConfig.useCheckbox && (
        <div className={styles.checkboxContainer}>
          <label>
            <input
              type="checkbox"
              checked={checkboxChecked}
              onChange={(e) => setCheckboxChecked(e.target.checked)}
            />
            {t(currentModalConfig.checkboxLabel)}
          </label>
          {checkboxWarning && (
            <p className={styles.warning}>{t(checkboxWarning)}</p>
          )}
        </div>
      )}

      <div className={styles.buttonContainer}>
        {currentModalConfig.buttonContainer.buttons.map((button, index) => {
          const isActionButton = ACTION_BUTTONS.includes(button.name);
          return (
            <button
              key={index}
              onClick={() => handleAction(button.name)}
              disabled={loading && isActionButton}
            >
              {loading && isActionButton ? (
                <img src={spinner} width={18} alt={t("Loading") + ".."} />
              ) : (
                button.text
              )}
            </button>
          );
        })}
      </div>
    </dialog>
  );
}
