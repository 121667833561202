import { assistantAzureRegexCheck } from "./assistantAzureRegexCheck.js";

export const handleAttachment = async (
  e,
  prompt,
  setPrompt,
  chat,
  setChat,
  addMessageBanner
) => {
  e.preventDefault();

  const maxFileSize = 50 * 1024 * 1024; // 50MB

  const attachmentsToUpload = Array.from(e.target.files).filter((file) => {
    if (file.size > maxFileSize) {
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: `The file size for ${file.name} exceeds 50MB.`,
      });
      return false;
    }
    return true;
  });

  if (
    chat.attachments.length +
      prompt.attachmentsToUpload.length +
      attachmentsToUpload.length >
    5
  ) {
    // TODO: Better message
    addMessageBanner({
      position: "topMiddle",
      type: "failure",
      text: "Maximum 5 files in total. Max 50MB per file.",
    });
    return;
  }

  if (attachmentsToUpload.length > 0 && assistantAzureRegexCheck(prompt.mode)) {
    setPrompt((prev) => {
      return {
        ...prev,
        attachmentsToUpload: [
          ...prev.attachmentsToUpload,
          ...attachmentsToUpload,
        ],
      };
    });

    for (const file of attachmentsToUpload) {
      try {
        const formData = new FormData();
        formData.append("prompt_files", file);

        const response = await fetch("/api/attachments/upload", {
          method: "POST",
          credentials: "include",
          body: formData,
        });

        if (response.ok) {
          const responseJson = await response.json();

          setChat((prev) => {
            return {
              ...prev,
              attachments: [...prev.attachments, ...responseJson],
            };
          });

          setPrompt((prev) => {
            return {
              ...prev,
              attachmentsToUpload: prev.attachmentsToUpload.filter(
                (f) => f !== file
              ),
            };
          });
        }
      } catch (error) {
        console.log("Unable to upload file", error);
      }
    }
  }
};
